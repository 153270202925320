import React, { useState, useEffect } from 'react';
import * as queryString from 'query-string';
import { Link } from 'gatsby';

import loader from '../images/jobs/loader.svg';
import Layout from '../components/Layout';
import MetaData from '../components/meta/MetaData';
import { extLinkClick } from '../utils/general';
import { Loader, ModalSpinner, Body } from '../styles';

const R = ({ location }) => {
	const [load, setLoad] = useState(true);
	const { code } = queryString.parse(location.search);

	useEffect(() => {
		setTimeout(() => {
			setLoad(false);
		}, 7000);
	}, []);

	useEffect(() => {
		if (code) extLinkClick(`https://envel.app.link/r-${code}`);
	}, [code]);

	return (
		<Layout>
			<MetaData
				title="Envel - Referral Code"
				description="Referral code for invitation to Envel"
				image="images/social/social.png"
			/>

			{load ? (
				<ModalSpinner>
					<Loader src={loader} />
				</ModalSpinner>
			) : code ? (
				<Body center style={{ margin: '80px auto' }}>
					If the page did not automatically redirect you, please click{' '}
					<a href={`https://envel.app.link/r-${code}`}>here</a>.
					<br />
					<br />
					Please click here to <Link to="/">return home.</Link>
				</Body>
			) : (
				<Body center style={{ margin: '80px auto' }}>
					This is not a valid referral link, please use on a valid referral link.
					<br />
					<br />
					Please click here to <Link to="/">return home.</Link>
				</Body>
			)}
		</Layout>
	);
};

export default R;
